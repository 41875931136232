
          @import "src/general/_colors.scss";
          @import "src/general/_fonts.scss";
          @import "src/general/_radius.scss";
        
.reset-password {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 420px;
    height: 445px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 8px 40px -4px rgba(28, 16, 32, 0.1);
    backdrop-filter: blur(10px);
    padding: 30px;
  }

  &__title {
    margin-top: 132px;
    font-size: 22px;
    font-family: "Rubik SemiBold";
    color: $dark;
  }

  &__text {
    margin-top: 12px;
    text-align: center;
    font-size: 16px;
    font-family: "Rubik Regular";
    color: $dark;
  }

  &__mail {
    margin-top: 2px;
    font-size: 16px;
    font-family: "Rubik Medium";
    color: $purpleLinear;
  }

  &__button {
    position: absolute;
    bottom: 30px;
    right: 30px;
    left: 30px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 18px;
    font-family: "Rubik SemiBold";
    color: $purpleLinear;

    border-radius: 20px;
    background: $white;
    box-shadow: 0px 8px 40px -4px rgba(28, 16, 32, 0.1);

    cursor: pointer;
  }
}
