
          @import "src/general/_colors.scss";
          @import "src/general/_fonts.scss";
          @import "src/general/_radius.scss";
        
.home-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;

  &__header {
    display: flex;
    align-items: center;
    min-width: 952px;
    width: 100%;
    height: 36px;
    background-color: #f2f2fb;
    border-radius: $radiusSmall;

    &-title {
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      font-family: "Rubik Medium";
      font-style: normal;
      font-weight: 500;
    }
  }

  &__empty {
    &-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 200px;
    }

    &-title {
      margin-top: 16px;

      font-size: 18px;
      font-family: "Rubik Medium";
      font-style: normal;
      font-weight: 600;
      color: $dark;
    }

    &-subtitle {
      width: 350px;
      margin-top: 8px;

      font-size: 16px;
      font-family: "Rubik Medium";
      font-style: normal;
      font-weight: 500;
      color: $grey;
      text-align: center;
    }
  }
}

.home-table-row {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 952px;
  width: 100%;
  height: 36px;
  background-color: $white;
  border-radius: $radiusSmall;
  box-shadow: 0px 3px 20px -4px rgba(28, 16, 32, 0.1);
  margin-top: 12px;
  cursor: pointer;
  transition: all 0.2s;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 3px 45px -4px rgba(28, 16, 32, 0.1);
  }

  &__title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    font-family: "Rubik Regular";
    font-style: normal;
    font-weight: 400;
    gap: 4px;
  }

  &__border {
    position: absolute;
    right: -6px;
    height: 100%;
    width: 10px;
    background-color: $green;
  }
}

.transaction-status {
  min-width: 141px;
  flex: 0.3;
}

.transaction-id {
  min-width: 181px;
  flex: 0.5;
}

.product-name {
  min-width: 272px;
  flex: 1;
}

.sum {
  min-width: 77px;
  flex: 0.1;
}

.transaction-date {
  min-width: 188px;
  flex: 0.3;
}

.status {
  min-width: 89px;
  flex: 0.3;
}
