
          @import "src/general/_colors.scss";
          @import "src/general/_fonts.scss";
          @import "src/general/_radius.scss";
        
.sign-in {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__content {
    width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: $radiusMain;
    box-shadow: 0px 8px 40px -4px rgba(28, 16, 32, 0.1);
    backdrop-filter: blur(10px);
    padding: 30px;
  }

  &__title {
    font-size: 26px;
    font-family: "Rubik Bold";
    font-weight: 700;
    color: $dark;
    margin-top: 5px;
  }

  &__subtitle {
    color: $grey;
    text-align: center;
    font-size: 16px;
    font-family: "Rubik Medium";
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &__row {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  &__label {
    font-size: 16px;
    font-family: "Rubik Medium";
    font-weight: 500;
    color: $dark;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  &__input {
    width: 100%;
    height: 48px;
    padding: 0 12px 0 12px;
    background-color: $lightBlue;

    font-size: 16px;
    font-family: "Rubik Regular";
    font-weight: 400;
    color: $dark;
    border: 0;
    border-radius: 12px;

    &--wrapper {
      position: relative;
      width: 100%;
      overflow: visible;
    }

    &::placeholder {
      font-size: 16px;
      font-family: "Rubik Regular";
      font-weight: 400;
      color: rgba(28, 16, 32, 0.4);
    }
  }

  &__password-button {
    position: absolute;
    top: 14px;
    left: 12px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__forgot-password {
    position: absolute;
    bottom: -30px;
    left: 0;
    font-family: "Rubik Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: $purpleLinear;
    cursor: pointer;
  }

  &__button {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $bottom_background;
    box-shadow: 0px 8px 40px -4px rgba(28, 16, 32, 0.1);
    border: 0;
    border-radius: $radiusMain;
    color: $white;
    font-size: 18px;
    font-family: "Rubik SemiBold";
    font-style: normal;
    font-weight: 600;
    margin-top: 75px;
    cursor: pointer;

    transition: all 0.2s;

    &:hover {
      box-shadow: 0px 8px 40px -4px rgba(28, 16, 32, 0.3);
    }
  }
}

.login-error {
  position: relative;
  width: 350px;
  height: 250px;
  border-radius: $radiusMain;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px 20px 20px;

  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__title {
    font-size: 20px;
    font-family: "Rubik SemiBold";
    color: $dark;
    width: 250px;
    text-align: center;
  }

  &__subtitle {
    margin-top: 8px;
    font-size: 16px;
    font-family: "Rubik Regular";
    color: $dark;
    text-align: center;
  }

  &__button {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $bottom_background;
    box-shadow: 0px 8px 40px -4px rgba(28, 16, 32, 0.1);
    border: 0;
    border-radius: $radiusMain;
    color: $white;
    font-size: 18px;
    font-family: "Rubik SemiBold";
    font-style: normal;
    font-weight: 600;
    cursor: pointer;

    transition: all 0.2s;

    &:hover {
      box-shadow: 0px 8px 40px -4px rgba(28, 16, 32, 0.3);
    }
  }
}

.hebrew {
  text-align: right;
}

.sign-in__input:focus {
  outline: none;
}

.sign-in__input:focus-within {
  border: 0;
}

.sign-in__input:focus {
  outline: none;
}

.sign-in__input:focus-within {
  border: 0;
}
