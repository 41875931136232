
          @import "src/general/_colors.scss";
          @import "src/general/_fonts.scss";
          @import "src/general/_radius.scss";
        
.home {
  width: 100%;
  height: 100vh;
  background-color: #f8f8fd;

  &__wrapper {
    width: 100%;
    height: 100vh;
    padding: 72px 20px 24px 20px;
  }

  &__content {
    width: 100%;
    min-width: 984px;
    height: 100%;
    background-color: $white;
    border-radius: $radiusLarge;
    box-shadow: 0px 8px 40px -4px rgba(28, 16, 32, 0.1);
    padding: 16px;
    overflow: hidden;
  }

  &__top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__credit-interface {
    padding: 9px 16px;
    background-color: $white;
    box-shadow: 0px 8px 40px -4px rgba(28, 16, 32, 0.1);
    border-radius: $radiusSmall;
    font-size: 15px;
    font-family: "Rubik Medium";
    font-weight: 500;
    color: $purpleLinear;
    border: 0;
    cursor: pointer;
  }

  &__title {
    font-size: 18px;
    font-family: "Rubik Medium";
    font-weight: 600;
    color: $dark;
  }
}
