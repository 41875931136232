@font-face {
  font-family: "Rubik Light";
  src: local("RubikLight"),
    url("../assets//fonts/Rubik-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Rubik Regular";
  src: local("RubikRegular"),
    url("../assets/fonts/Rubik-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Rubik Medium";
  src: local("RubikMedium"),
    url("../assets/fonts/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Rubik SemiBold";
  src: local("RubikSemiBold"),
    url("../assets/fonts/Rubik-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Rubik Bold";
  src: local("RubikBold"),
    url("../assets/fonts/Rubik-Bold.ttf") format("truetype");
  font-weight: 700;
}
