
          @import "src/general/_colors.scss";
          @import "src/general/_fonts.scss";
          @import "src/general/_radius.scss";
        
.header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: $white;
  box-shadow: 0px 8px 40px -4px rgba(28, 16, 32, 0.1);
  padding: 0 20px 0 20px;

  &__disconnection {
    font-size: 16px;
    font-family: "Rubik Medium";
    font-style: normal;
    font-weight: 500;
    color: $purpleLinear;
    cursor: pointer;
  }

  &__shop-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__shop-ip {
    position: relative;
    font-size: 16px;
    font-family: "Rubik Regular";
    font-weight: 400;
    color: $dark;
    margin-right: 4px;
  }

  &__shop-name {
    position: relative;
    font-size: 16px;
    font-family: "Rubik Medium";
    font-weight: 500;
    color: $dark;
    margin-right: 6px;
  }

  &__shop-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 7.5px;
    margin-left: 6px;
    overflow: hidden;
    background-color: $grey;
  }
}
