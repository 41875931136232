
          @import "src/general/_colors.scss";
          @import "src/general/_fonts.scss";
          @import "src/general/_radius.scss";
        
.timer {
  font-size: 16px;
  font-family: "Rubik Medium";
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: $grey;
}
