$white: #fff;
$purpleLinear: #d354ff;
$purpleSecond: #bd00ff;
$dark: #1c1020;
$grey: #adadb2;
$lightBlue: #f2f2fb;
$light: #BFBFE7;

$red: #ff776f;
$secondaryRed: #ff5f5f;
$green: #18cb75;
$bottom_background: linear-gradient(
  193deg,
  #d354ff 0%,
  #ca30ff 100%,
  #ca30ff 100%
);
