
          @import "src/general/_colors.scss";
          @import "src/general/_fonts.scss";
          @import "src/general/_radius.scss";
        
.transaction {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 409px;
  height: 419px;
  padding: 24px;

  background-color: $white;
  border-radius: $radiusMain;

  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__title {
    margin-bottom: 12px;
    font-size: 20px;
    font-family: "Rubik Medium";
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    text-align: right;
    color: $dark;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;

    &-text {
      margin-right: 40px;
      font-size: 16px;
      font-family: "Rubik Medium";
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      color: $dark;
    }

    &-title {
      font-size: 16px;
      font-family: "Rubik Regular";
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }

  &__discount {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 4px 8px;
    margin-top: 12px;
    border-radius: $radiusSmall;
    background: var(--primary-4, #f9e9ff);
    font-size: 16px;
    font-family: "Rubik Medium";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $purpleLinear;
    gap: 4px;
  }

  &__details {
    width: 100%;
    height: 80px;
    resize: none;
    border: 0;
    background-color: $lightBlue;
    border-radius: $radiusSmall;
    text-align: right;
    padding: 8px;
    font-size: 16px;
    font-family: "Rubik Regular";
    font-style: normal;
    font-weight: 400;
    line-height: 132%;
    color: $dark;
    margin-top: 8px;

    &::placeholder {
      font-size: 16px;
      font-family: "Rubik Regular";
      font-style: normal;
      font-weight: 400;
      line-height: 132%;
      color: #a09ba5;
    }

    &-title {
      margin-top: 28px;
      font-size: 16px;
      font-family: "Rubik Medium";
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      color: $dark;
    }

    &-subtitle {
      margin-top: 4px;
      font-size: 16px;
      font-family: "Rubik Medium";
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      color: $grey;
    }
  }

  &__buttons {
    position: absolute;
    bottom: 24px;
    left: 24px;
    right: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-top: 24px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: $radiusMain;
    flex: 1;
    gap: 4px;
    font-size: 18px;
    font-family: "Rubik Bold";
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $white;
    border: 0;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      box-shadow: 0px 8px 15px -4px rgba(28, 16, 32, 0.25);
    }
  }

  &__timer {
    position: absolute;
    right: 278px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    font-family: "Rubik Medium";
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: $grey;
  }
}

.transaction-cancel {
  &__title {
    font-size: 20px;
    font-family: "Rubik Medium";
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: $dark;
  }

  &__subtitle {
    margin-top: 24px;
    font-size: 16px;
    font-family: "Rubik Medium";
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: $dark;
  }

  &__text {
    margin-top: 4px;
    font-size: 16px;
    font-family: "Rubik Medium";
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: $grey;
  }

  &__buttons {
    position: absolute;
    left: 24px;
    right: 24px;
    bottom: 24px;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
    }
  }
}

.accept {
  background: linear-gradient(171deg, #49d692 0%, #18cb75 100%);
}

.cancel {
  background: linear-gradient(176deg, #ff7474 0%, #ff4545 100%);
}

.transaction__details:focus {
  outline: none;
}

.transaction__details:focus-within {
  border: 0;
}

.transaction__details:focus {
  outline: none;
}

.transaction__details:focus-within {
  border: 0;
}

.react-controlled-select {
  width: 100%;
}

// :global {
.react-select {
  &__menu-portal {
    z-index: 999 !important;
  }

  &__menu-list {
    max-height: 300px;
    overflow-y: auto;
  }

  &__control {
    height: 36px !important;
    width: 100%;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    border: 0 !important;
    background-color: $white !important;
    box-shadow: 0px 8px 20px -4px rgba(28, 16, 32, 0.1);
    border-radius: $radiusMain !important;
    padding: 0 8px !important;
    text-align: right !important;

    font-size: 15px;
    font-family: "Rubik Regular";

    &--menu-is-open {
      border: 0 !important;
    }
  }

  &__value-container {
    flex: 1;
    overflow: hidden;
    align-items: center;
    display: flex;
    border: 0;
  }

  &__menu {
    position: absolute;
    top: -40px;
    width: 100%;
    z-index: var(--dropdown-container-z-index);
    margin-top: 0 !important;
    background-color: $white;
    border-radius: 0 0 $radiusMain $radiusMain !important;
    border-color: $white !important;

    &-list {
      border: 0 !important;
    }
  }

  &__option {
    padding: 14px 20px;
    cursor: pointer;
    border: 0 !important;

    &--is-selected {
      background-color: var(--background-color) !important;
    }

    &:not(&--is-selected):hover {
      background-color: hsla(
        var(--background-h-color),
        var(--background-s-color),
        var(--background-l-color),
        0.5
      );
    }
  }

  &--single-value {
    max-width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 50%;
    background: #2b3352;
  }

  &__single-value {
    min-width: max-content;
  }

  &__menu-notice {
    &--no-options {
      text-align: center;
      padding: 5px 0;
    }
  }

  &__placeholder {
    font-size: 15px;
    font-family: "Rubik Regular";
    color: #a09ba5 !important;
    min-width: max-content;
  }
}

.select {
  width: 100%;
  height: 36px;
  border-radius: $radiusMain;
  background-color: $white;
  display: flex;
  align-items: center;
  margin-top: 18.5px;
  padding: 0 8px;
  box-shadow: 0px 0px 30px -4px rgba(28, 16, 32, 0.1);
  position: relative;
  z-index: 2;

  &--wrapper {
    width: 100%;
    position: relative;
    cursor: pointer;
  }

  &--open {
    border-radius: $radiusMain $radiusMain 0 0;
    box-shadow: none;
  }

  &__indicator {
    position: absolute;
    left: 8px;
    top: 11px;
  }

  &__input {
    width: 100%;
    height: 100%;
    border: 0;
    text-align: right;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    font-family: "Rubik Regular";
    font-style: normal;
    font-weight: 400;
    color: $dark;

    &::placeholder {
      font-size: 16px;
      font-family: "Rubik Regular";
      font-style: normal;
      font-weight: 400;
      color: #a09ba5;
    }
  }

  &__dropdown {
    position: absolute;
    top: 18.5px;
    left: 0;
    z-index: 1;

    width: 100%;
    height: max-content;
    background-color: $white;
    box-shadow: 0px 0px 30px -4px rgba(28, 16, 32, 0.1);
    border-radius: $radiusMain;
    padding-bottom: 8px;

    &--separator {
      width: 100%;
      height: 1px;
      background-color: #d9d9f1;
      margin-top: 36px;
    }
  }

  &__options {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 8px;
  }

  &__option {
    font-size: 16px;
    font-family: "Rubik Regular";
    font-style: normal;
    font-weight: 400;
    color: $dark;
  }
}

.select__input:focus {
  outline: none;
}

.select__input:focus-within {
  border: 0;
}

.select__input:focus {
  outline: none;
}

.select__input:focus-within {
  border: 0;
}

.cancel-button {
  flex: 1;
  height: 48px;
  border-radius: 20px;
  background: linear-gradient(176deg, #ff7474 0%, #ff4545 100%);
  box-shadow: 0px 8px 40px -4px rgba(28, 16, 32, 0.16);
  border: 0;
  font-size: 18px;
  font-family: "Rubik SemiBold";
  font-weight: 600;
  color: $white;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0px 8px 40px -4px rgba(28, 16, 32, 0.4);
  }
}

.return-button {
  min-width: 120px;
  height: 48px;
  border-radius: 20px;
  background: $white;
  box-shadow: 0px 8px 40px -4px rgba(28, 16, 32, 0.16);
  border: 0;
  font-size: 18px;
  font-family: "Rubik SemiBold";
  font-weight: 600;
  color: $purpleLinear;

  transition: all 0.2s;

  &:hover {
    box-shadow: 0px 8px 40px -4px rgba(28, 16, 32, 0.25);
  }
}

.transaction-result {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__cross {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 20px -4px rgba(28, 16, 32, 0.15);
    border: 0;

    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      box-shadow: 0px 0px 30px -4px rgba(28, 16, 32, 0.3);
    }
  }

  &__circle {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background: linear-gradient(171deg, #49d692 0%, #18cb75 100%);

    &-cancel {
      background: linear-gradient(176deg, #ff7474 0%, #ff4545 100%);
    }

    &--inner {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background: linear-gradient(171deg, #49d692 0%, #18cb75 100%);
      border: 4px $white solid;
      box-sizing: border-box;

      &-cancel {
        background: linear-gradient(176deg, #ff7474 0%, #ff4545 100%);
      }
    }
  }

  &__title {
    margin-top: 24px;
    font-size: 22px;
    font-family: "Rubik Bold";
    font-style: normal;
    font-weight: 700;
    color: $dark;
  }

  &__subtitle {
    margin-top: 4px;

    font-size: 20px;
    font-family: "Rubik Regular";
    font-style: normal;
    font-weight: 400;
    color: $dark;
  }

  &__id {
    position: absolute;
    bottom: 0;
    font-size: 16px;
    font-family: "Rubik Medium";
    font-style: normal;
    font-weight: 500;
    color: $grey;
  }
}
